import React from 'react';
import './App.css';
import './Upload.css';

function ImageUpload() {
    const [filePath, setFilePath] = React.useState();
    const [image, setImage] = React.useState<any>(null);
    const [restoredImageUrl, setRestoredImageUrl] = React.useState<string>();
    const [fileType, setFileType] = React.useState();

    const handleSubmit = (event: any) => {
        event.preventDefault();
        var fileTypeHeader = 'image/' + fileType;
        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeHeader },
            body: image
        };
        fetch('/restore', requestOptions)
            .then(response => {
                console.log("image response: ", response);
                return response.blob();
            })
            .then(data => {
                console.log("image data: ", data);
                const objectURL = URL.createObjectURL(data);
                setRestoredImageUrl(objectURL);
            });
    }

    const handleChange = (event: any) => {
        setFilePath(event.target.value);
        setFileType(checkFileType(event.target.value));
        if (event.target.files && event.target.files[0]) {
          var reader = new FileReader();

          reader.onload = function (e) {
            setImage(e.target?.result);
          }
      
          reader.readAsDataURL(event.target.files[0]);
        }
    }

    const checkFileType = (something: any) => {
        return something.split(".").pop();
    }

    return (
        <div>
            <img src={image} className="image"></img>
            <img src={restoredImageUrl} className="image"></img>
            <form onSubmit={handleSubmit}>
                <label>
                    Upload Image:
                    <input type="file" accept="image/*" value={filePath} onChange={handleChange} />
                </label>
                <input type="submit" value="Upload Image" />
            </form>
        </div>
    );
}

export default ImageUpload;
