import React from 'react';
import logo from './logo.svg';
import './App.css';
import URLUpload from './URLUpload';
import ImageUpload from './ImageUpload';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Image Restore</h1>
        <ImageUpload></ImageUpload>
        <URLUpload></URLUpload>
      </header>
    </div>
  );
}

export default App;
