import React from 'react';
import './App.css';
import './Upload.css';

function URLUpload() {
    const [imageUrl, setImageUrl] = React.useState<string>();
    const [restoredImageResponse, setRestoredImageResponse] = React.useState<string>();

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ url: imageUrl })
        };

        fetch('/restore', requestOptions)
            .then((response) => {
                console.log("url response: ", response);
                return response.blob();
            })
            .then((data) => {
                console.log("url data: ", data);
                const objectURL = URL.createObjectURL(data);
                setRestoredImageResponse(objectURL);
            });            
    }

    const handleChange = (event: any) => {
        setImageUrl(event.target.value);
    }

    return (
        <div>
            <img src={imageUrl} className="image"></img>
            <img src={restoredImageResponse} className="image"></img>
            <form onSubmit={handleSubmit}>
                <label>
                    Upload Image Url:
                    <input type="text" value={imageUrl} onChange={handleChange} />
                </label>
                <input type="submit" value="Submit" />
            </form>
        </div>
    );
}

export default URLUpload;
